import vandyLogo from "../Assets/Images/VanderbiltLog.png";
import columbiaLogo from "../Assets/Images/ColumbiaLogo.png";
import lurieLogo from "../Assets/Images/LurieLogo.png";
import { Element } from "react-scroll";

const luriePeople = [
  {
    name: "Robert Garofalo",
    role: "Professor",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQGIq75cusGn1g/profile-displayphoto-shrink_800_800/0/1516839250512?e=1707350400&v=beta&t=XRcqdYx0Z7WjL0wNXmJVVEyvo_dZQufM2q-iUYCPoJQ",
  },
  {
    name: "Lisa Kuhns",
    role: "Research Professor",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQGT_cqljdlWTg/profile-displayphoto-shrink_800_800/0/1567114863539?e=1707350400&v=beta&t=gmcc_3ODHBAe_0sejWJro0Koq_y0Kbnvy0yH8e8Xo0w",
  },
  {
    name: "Nate Nash",
    role: "Research Coordinator",
    imageUrl:
      "https://www.tuktukdesign.com/wp-content/uploads/2020/01/user-icon-vector.jpg",
  },
  {
    name: "Afiya Sajwani",
    role: "Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQEW5cyDHCH6vA/profile-displayphoto-shrink_400_400/0/1543004599718?e=1707350400&v=beta&t=OH1sb6VgWngGdkgWARohakFCUvonJ3AMXp_8uiV4GpY",
  },
  {
    name: "Michael Almodovar",
    role: "Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQF3ueXsQIMIEg/profile-displayphoto-shrink_800_800/0/1559146723857?e=1707350400&v=beta&t=WcHPaGC_zcwUkCjSK2DY4ILGnmg9LJSpKxk2Q7UxI1k",
  },
  {
    name: "Rose Diskin",
    role: "Research Coordinator",
    imageUrl:
      "https://www.tuktukdesign.com/wp-content/uploads/2020/01/user-icon-vector.jpg",
  },
];

const vanderbiltPeople = [
  {
    name: "Tom Scherr",
    role: "Research Associate Professor",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQFm3-whp5N-rQ/profile-displayphoto-shrink_400_400/0/1652369229633?e=1707350400&v=beta&t=MDC7948eJwjvWq4tnjgL1Vj0zoYKhJ3CaXrx_1_Gq4E",
  },
  {
    name: "Austin Hardcastle",
    role: "Product Manager",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQGHvVrFxtN34w/profile-displayphoto-shrink_400_400/0/1651849758623?e=1707350400&v=beta&t=G0bbiNRkjcPG6DpGghaXx72Klfo9eXlsgtLdPdPI6CA",
  },
  {
    name: "Matthew Boisse",
    role: "Application Developer",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQFk8H7WlrfH7w/profile-displayphoto-shrink_400_400/0/1655846578788?e=1707350400&v=beta&t=V2BqXuAwzlfleO4nd66bpAwIGEjoFAXUlJRVf-Y09W4",
  },
  {
    name: "Carson Moore",
    role: "Postdoctoral Scholar",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQEsGZCWO0eICw/profile-displayphoto-shrink_400_400/0/1582060432538?e=1707350400&v=beta&t=njBWhF-OxHSzCq2qZeHK4K6BYVYsO8H8Vsr2ohpF5-E",
  },
  {
    name: "Dheemanth Majji",
    role: "Data Analyst",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQGpNwlVbHoLJQ/profile-displayphoto-shrink_200_200/0/1695760136536?e=2147483647&v=beta&t=CDZHMNqFckejAxuNwJVIjmjWAOHGMrNzQorMrHa2cXI",
  },
];

const columbiaPeople = [
  {
    name: "Rebecca Schnall",
    role: "Professor",
    imageUrl:
      "https://mr.research.columbia.edu/sites/default/files/styles/cu_crop/public/content/Cropped%20head%20shots_Niki/Schnall.png?itok=wLtmVV2J",
  },
  {
    name: "Olivia Wood",
    role: "Sr Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQEOYXKfzkMfEQ/profile-displayphoto-shrink_400_400/0/1588974598393?e=1707350400&v=beta&t=OtA8ijxAI6xdne7u8DTrVua5sWrdI-cRA18gYaDUsAw",
  },
  {
    name: "Jhoan Dutton",
    role: "Program Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQHWi4qdoSN_iw/profile-displayphoto-shrink_400_400/0/1657308787357?e=1707350400&v=beta&t=PcHSdHRPcp549E9E42O1nEF27ukVw-EDpY-ak7TR8gI",
  },
  {
    name: "Ana Mata",
    role: "Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQH_jNbjauebpg/profile-displayphoto-shrink_400_400/0/1653403126835?e=1707350400&v=beta&t=_Jp53xfLVxsC0r_ZUi594_2G2-6omPnUGySEATmYj1I",
  },
  {
    name: "Sarah Ganzhorn",
    role: "Data Analyst",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQElVaH0snzHJg/profile-displayphoto-shrink_400_400/0/1546989610805?e=1707350400&v=beta&t=c-fUPnnab2dGUL74PPMSjQbBqTCnVc5_5Z-zHEgc8T4",
  },
  {
    name: "Brady Bushover",
    role: "Project Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQGw2BGrpEFxvw/profile-displayphoto-shrink_400_400/0/1646758141710?e=1707350400&v=beta&t=7_I3Yk4FAuHiGhi-0Na8U4mt2suochYc4NFfWrG98mA",
  },
  {
    name: "Rafael Garibay",
    role: "Assistant Project Manager",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQG-pNT6cEFE5Q/profile-displayphoto-shrink_400_400/0/1596854070336?e=1707350400&v=beta&t=f95h3HNsS4yCa5Lm2ujmQX4JPlR47gGopV_NJnojdMU",
  },
  {
    name: "Janeth Padilla",
    role: "Sr Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQHZ8jV8e34w4w/profile-displayphoto-shrink_400_400/0/1594742398757?e=1707350400&v=beta&t=WIqa4M5qav64d3wAVtViUZ1c1GgYyQcpCcYMG-I9xTI",
  },
  {
    name: "Gabby Flynn",
    role: "Program Coordinator",
    imageUrl:
      "http://my.vanderbilt.edu/peabodycda/files/2018/02/Unknown-1.jpeg",
  },
  {
    name: "Sarah Ashrafnia",
    role: "Research Associate",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQEEyvSXwCihiw/profile-displayphoto-shrink_400_400/0/1604698513868?e=1707350400&v=beta&t=zNZ6qRpViGodgmayvUfGBe0BTlxOt4Z6HtXca3r5HGM",
  },
  {
    name: "Brittney Sweetser",
    role: "Research Assistant",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQFDtB_v2NMO8g/profile-displayphoto-shrink_400_400/0/1619718637471?e=1707350400&v=beta&t=PK3a4y2a74w7VS25WXN_6UwBn_W-_yj0cPlj4-VUt7s",
  },
  {
    name: "Matthew Stolman",
    role: "Research Associate",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQES-b0AFA1yvw/profile-displayphoto-shrink_400_400/0/1594237538273?e=1707350400&v=beta&t=pD4xLAMqQxxODewSC3rdFrvjUq7TnncBdUQ4yBVsKpw",
  },
  {
    name: "Kathleen Brody",
    role: "Research Assistant",
    imageUrl:
      "https://media.licdn.com/dms/image/D4E03AQGn2aQ8niM_Sg/profile-displayphoto-shrink_400_400/0/1688758366351?e=1707350400&v=beta&t=k8MTZaBI2AZ6iUzUVWSk6KJiHIzdlQCab9VTMXkUllY",
  },
  {
    name: "Adrienne Pichon",
    role: "Project Manager",
    imageUrl:
    "https://media.licdn.com/dms/image/C4D03AQEvMCyInGZxvA/profile-displayphoto-shrink_800_800/0/1570471595545?e=1707955200&v=beta&t=5EiYxg2Iuu0SoujfjrEfanqAExCtSw6BocUnIAzFF-Y"
  },
  {
    name: "Ian Esliker",
    role: "Research Associate",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQFhemvWW-87yQ/profile-displayphoto-shrink_400_400/0/1684824271788?e=1707350400&v=beta&t=COncd4WlBwedwHOSNKzfEe6QZlxLsayuoo9rfWdsrRs",
  },
  {
    name: "Dorcas Adedoja",
    role: "Research Coordinator",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQHMbxERyoROKw/profile-displayphoto-shrink_400_400/0/1612205671626?e=1707350400&v=beta&t=pfVGJKCvckZ2DFLA1taTh2dq5zeCYxV83FIrngAIkcY",
  },
];

export default function Team() {
  return (
    <Element name="studyteam">
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            The mLabApp Study Team
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We're an interdisciplinary group from Columbia University in New
            York City, Lurie Children's Hospital in Chicago, and Vanderbilt
            University in Nashville.
          </p>
        </div>

        {/* Columbia People */}
        <div className="mx-auto grid justify-items-stretch max-w-7xl gap-x-8 pt-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl mx-auto">
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900">Columbia University</h3> */}
            <img
              className="max-h-20 w-80 object-contain"
              src={columbiaLogo}
              alt="Vanderbilt University"
            />
            {/* <p className="mt-6 text-lg leading-8 text-gray-600">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
              suspendisse.
            </p> */}
          </div>
        </div>
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 pt-4 px-6 lg:px-8 xl:grid-cols-3">
          <ul
            role="list"
            className="grid gap-x-1 gap-y-8 sm:grid-cols-3 lg:grid-cols-4 sm:gap-y-16 xl:col-span-5"
          >
            {columbiaPeople.map((columbiaPerson) => (
              <li key={columbiaPerson.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={columbiaPerson.imageUrl}
                    alt=""
                  />
                  <div>
                    <h4 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {columbiaPerson.name}
                    </h4>
                    <p className="text-sm font-semibold leading-6 text-blue-600">
                      {columbiaPerson.role}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Lurie */}
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-4 pt-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl mx-auto">
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900">Columbia University</h3> */}
            <img
              className="max-h-20 w-80 object-contain"
              src={lurieLogo}
              alt="Lurie Children's Hospital"
            />
            {/* <p className="mt-6 text-lg leading-8 text-gray-600">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
              suspendisse.
            </p> */}
          </div>
        </div>
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 pt-4 px-6 lg:px-8 xl:grid-cols-3">
          <ul
            role="list"
            className="grid gap-x-1 gap-y-8 sm:grid-cols-3 lg:grid-cols-4 sm:gap-y-16 xl:col-span-5"
          >
            {luriePeople.map((luriePerson) => (
              <li key={luriePerson.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={luriePerson.imageUrl}
                    alt=""
                  />
                  <div>
                    <h4 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {luriePerson.name}
                    </h4>
                    <p className="text-sm font-semibold leading-6 text-blue-600">
                      {luriePerson.role}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Vanderbilt People */}
        <div className="mx-auto grid justify-items-stretch max-w-7xl gap-x-8 pt-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl mx-auto">
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900">Columbia University</h3> */}
            <img
              className="max-h-20 w-80 object-contain"
              src={vandyLogo}
              alt="Vanderbilt University"
            />
            {/* <p className="mt-6 text-lg leading-8 text-gray-600">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
              suspendisse.
            </p> */}
          </div>
        </div>
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-10 pt-4 px-6 lg:px-8 xl:grid-cols-3">
          <ul
            role="list"
            className="grid gap-x-1 gap-y-8 sm:grid-cols-3 lg:grid-cols-4 sm:gap-y-10 xl:col-span-5"
          >
            {vanderbiltPeople.map((vanderbiltPerson) => (
              <li key={vanderbiltPerson.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={vanderbiltPerson.imageUrl}
                    alt=""
                  />
                  <div>
                    <h4 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {vanderbiltPerson.name}
                    </h4>
                    <p className="text-sm font-semibold leading-6 text-blue-600">
                      {vanderbiltPerson.role}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Element>
  );
}
