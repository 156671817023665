import logo from "./logo.svg";
import "./App.css";
import Hero from "./Components/Hero";
import LogoCloud from "./Components/LogoCloud";
import MissionWithStats from "./Components/MissionWithStats";
import Team from "./Components/Team";
import PubList from "./Components/PubList";
import Contact from "./Components/Contact";
import Header from "./Components/Header";
import PubFilters from "./Components/PubFilters";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <MissionWithStats />
      <Team />
      <PubList />
      <Contact />
    </>
  );
}

export default App;
