import { useState, useEffect } from "react";
import { Element } from "react-scroll";
import PubFilters from "./PubFilters";
import usabilityEvaluationScreenshot from "../Assets/Images/screenshot_usability_evaluation_of_the_mlab_app_for_improving_home_hiv_testing.png"
import randomizedControlledScreenshot from "../Assets/Images/screenshot_a_randomized_controlled_trial_of_an_mHealth_intervention_for_increasing_access_to_HIV_testing_and_care.png"
import inDepthInterviewsToScreenshot from "../Assets/Images/screenshot_in_depth_interviews_to_understand_the_feasibility_of_using_the_mlab_app.png"
import theMlabAppAnMHealthScreenshot from "../Assets/Images/screenshot_the_mlab_app_an_mHealth_intervention_for_increasing_access_to_HIV_testing_and_care.png"

const initPosts = [
  {
    id: 1,
    title: "Usability Evaluation of the mLab App for Improving Home HIV Testing Behaviors in Youth at Risk of HIV Infection",
    href: "https://guilfordjournals-com.proxy.library.vanderbilt.edu/doi/abs/10.1521/aeap.2021.33.4.312",
    description:
      "Improving access to HIV testing among youth at high risk is essential for reaching those who are most at risk for HIV and least likely to access health care services. This study evaluates the usability of mLab, an app with image-processing feature that analyzes photos of OraQuick HIV self-tests and provides real-time, personalized feedback. mLab includes HIV preven- tion information, testing reminders, and instructions. It was developed through iterative feedback with a youth advisory board (N = 8). The final design underwent heuristic (N = 5) and end-user testing (N = 20). Experts rated mLab following Nielsen’s heuristic checklist. End-users used the Health Information Technology Usability Evaluation Scale. While there were some usability problems, overall study participants found mLab useful and user-friendly. This study provides important insights into using a mobile app with imaging for interpreting HIV test results with the goal of improv- ing HIV testing and prevention in populations at high risk.",
    image: usabilityEvaluationScreenshot,
    date: "Aug 1, 2021",
    datetime: "2021-08-01",
    category: { title: "Usability" },
    type: { title: "Publication" },
    citations: 10,
  },
  {
    id: 2,
    title: "A randomized controlled trial of an mHealth intervention for increasing access to HIV testing and care among young cisgender men and transgender women: the mLab App study protocol",
    href: "https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-021-12015-w",
    description:
      "This study is a three-arm randomized controlled trial among YMSM and YTGW aged 18–29 years. Participants are randomized to either the mLab App intervention including HIV home test kits and standard of preventive care, standard of preventive care only, or HIV home test kits and standard of preventive care only. mHealth technology used for HIV prevention is capable of delivering interventions in real-time, which creates an opportunity to remotely reach users across the country to strengthen their HIV care continuum engagement and treatment outcomes. Specifically during the COVID-19 pandemic, mHealth technology combined with at-home testing may prove to be essential in increasing HIV testing rates, especially among populations at high-risk or without regular access to HIV testing.",
    image: randomizedControlledScreenshot,
    date: "Oct 29, 2021",
    datetime: "2021-10-29",
    category: { title: "Protocol" },
    type: { title: "Publication" },
    citations: 10,
  },
  {
    id: 3,
    title: "In-depth Interviews to Understand the Feasibility of Using the mLab App for Promotion of HIV-Self Testing in Young Men",
    href: "https://apha.confex.com/apha/2018/meetingapi.cgi/Paper/406234?filename=2018_Abstract406234.pdf&template=Word",
    description:
      "Mobile Health (mHealth) technology is a powerful and relevant tool which represents a promising approach for improving outcomes among youth living with and at-risk for HIV. In response, our study team developed the mLab App, an innovative mobile and connected technology that combines HIV prevention information with push notifications/reminders to complete HIV testing and an automated image processing feature to provide accessible, objective, secure, and real-time feedback on home-based OraQuick (lateral flow assay) HIV test results.",
    image: inDepthInterviewsToScreenshot,
    date: "Nov 10, 2018",
    datetime: "2018-11-10",
    category: { title: "Usability" },
    type: { title: "Abstract" },
    citations: 1,
  },
  {
    id: 4,
    title: "The mLab App: an mHealth intervention for increasing access to HIV testing and care among YMSM and YTGW",
    href: "https://apha.confex.com/apha/2022/meetingapp.cgi/Paper/518306",
    description:
      "The number of youth living with HIV in the US continues to rise, and racial, ethnic, and sexual minority youth including YMSM and YTGW bear a disproportionate burden of the epidemic. Due to social and healthcare system factors, many YMSM and YTGW do not seek HIV testing services and may not be aware they are infected.",
    image: theMlabAppAnMHealthScreenshot,
    date: "Nov 8, 2022",
    datetime: "2022-11-08",
    category: { title: "Usability" },
    type: { title: "Abstract" },
    citations: 2,
  },
];

const initSortOptions = [
  { name: "Newest", current: true },
  { name: "Oldest", current: false },
];
const initFilters = [
  {
    id: "Type",
    name: "Type",
    options: [
      { value: "Press", label: "Press Release", checked: false },
      {
        value: "Publication",
        label: "Peer-Reviewed Publication",
        checked: false,
      },
      { value: "Abstract", label: "Conference Abstract", checked: false },
    ],
  },
  {
    id: "Category",
    name: "Category",
    options: [
      { value: "Usability", label: "Usability", checked: false },
      { value: "Testing", label: "Testing", checked: false },
      { value: "PrEP", label: "PrEP", checked: false },
      { value: "Protocol", label: "Protocol", checked: false },
      { value: "Paradata", label: "Paradata", checked: false },
    ],
  },
];


const sortPosts = (posts, direction) => {
  const sortedPosts = [...posts].sort((a, b) => {
    const dateA = new Date(a.datetime);
    const dateB = new Date(b.datetime);

    if (direction === 'Newest') {
      return dateB - dateA;
    } else {
      return dateA - dateB;
    }
  });
  return sortedPosts
}

const getSortDirection = (sortOptions) => {
  const sortOption = sortOptions.find(option => option.current === true);
  const sortDirection = sortOption ? sortOption.name : 'Newest'
  return sortDirection;
}

const filterPosts = (posts, filters) => {

  if (filters.length === 0) {
    return posts;
  }

  return posts.filter(post => {
    return filters.every(filter => {
      const filterKey = filter.type.toLowerCase(); // Convert filter type to lowercase
      if (post[filterKey] && post[filterKey].title) {
        return post[filterKey].title === filter.value;
      }
      return false;
    });
  });
};


export default function PubList() {
  const [activeFilters, setActiveFilters] = useState([]);
  const [filters, setFilters] = useState(initFilters);
  const [visiblePosts, setVisiblePosts] = useState(sortPosts(initPosts))
  const [sortOptions, setSortOptions] = useState(initSortOptions, getSortDirection(initSortOptions));

  
  useEffect(() => {

    const filteredPosts = filterPosts(initPosts, activeFilters)
    setVisiblePosts(sortPosts(filteredPosts, getSortDirection(sortOptions)))
    
  }, [sortOptions, activeFilters])
  return (
    <Element name="results">
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Publications and Press
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              See results here as they are published
            </p>
          </div>

          <PubFilters 
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filters={filters}
            setFilters={setFilters}
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
          />

          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {visiblePosts.map((post) => (
              <article
                key={post.id}
                className="flex flex-col items-start justify-between"
              >
                <div className="relative w-full">
                  <img
                    src={post.image}
                    alt=""
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <div
                      className="relative z-10 rounded-full bg-gray-50 border-2 border-blue-600 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </div>
                    <div
                      className="relative z-10 rounded-full bg-white border-2 border-gray-600 ml-2 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-50"
                    >
                      {post.type.title}
                    </div>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg line-clamp-3 font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                      {post.description}
                    </p>
                  </div>
                  {/* <div className="relative mt-8 flex items-center gap-x-4">
                    <img
                      src={post.author.imageUrl}
                      alt=""
                      className="h-10 w-10 rounded-full bg-gray-100"
                    />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a href={post.author.href}>
                          <span className="absolute inset-0" />
                          {post.author.name}
                        </a>
                      </p>
                      <p className="text-gray-600">{post.author.role}</p>
                    </div>
                  </div> */}
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </Element>
  );
}
