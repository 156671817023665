import { Element } from "react-scroll";
import Timeline from "./Timeline";

const stats = [
  { label: "Participants enrolled", value: "525" },
  { label: "mLabApp accounts created", value: "248" },
  { label: "Tests performed in mLab", value: "330" },
  { label: "HIV educational facts presented", value: "2,753" },
  { label: "In-app user actions", value: "100,467" },
];

export default function MissionWithStats() {
  return (
    <Element name="overview">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-24 sm:py-32">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Overview
          </h2>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <p className="text-xl leading-8 text-gray-600">
                The mLab App was developed to combine HIV prevention information
                with a mobile phone imaging feature for interpreting at-home HIV
                test results, with the ultimate goal of improving testing rates
                and linkage to care among Black, Latino, and other YMSM and YTGW
                living in New York City and Chicago and their surrounding areas.
              </p>
              <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                <p>
                  Prior to the trial, usability analyses were performed with multiple groups: a Youth Advisory Board, prospective end-users, and mHealth usability experts.
                  These evaluations identified some usability problems, which were iteratively improved. 
                  Overall study participants found mLab useful and user-friendly. 
                </p>
                <p className="mt-10">
                  As part of this{" "}
                  <a href="https://clinicaltrials.gov/study/NCT03803683?term=mlab%20app&rank=2">
                    study
                  </a>
                  the mLabApp received an Investigational Device Exemption from
                  the US Food & Drug Administration. The study was a three-arm
                  randomized controlled trial among YMSM and YTGW aged 18-29
                  years. Participants were randomized to either the mLab App
                  intervention including HIV home test kits and standard of
                  preventive care, standard of preventive care only, or HIV home
                  test kits and standard of preventive care only.
                </p>
              </div>
            </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <dl className="w-64 space-y-8 xl:w-80">
                {stats.map((stat) => (
                  <div
                    key={stat.label}
                    className="flex flex-col-reverse gap-y-4"
                  >
                    <dt className="text-base leading-7 text-gray-600">
                      {stat.label}
                    </dt>
                    <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
        <Timeline />
      </div>
    </Element>
  );
}
