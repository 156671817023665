import { useState } from "react";
import { Element } from "react-scroll";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

async function postData(url = "", data = {}) {
  try {
    const response = await axios.post(url, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error.response);
    // Handle errors here
  }
}

export default function Contact() {
  const submitMessageHandler = () => {
    // Make API call
    const sendEmailURL =
      "https://7xww630a5i.execute-api.us-east-1.amazonaws.com/Production/sendWebContactEmail";
    const postPayload = {
      webhost: "mlabapp",
      email: emailValue,
      message: messageValue,
    };
    postData(sendEmailURL, postPayload).then((response) => {
      console.log(response);

      if (response.statusCode === 200) {
          // Reset states
          setEmailValue("");
          setMessageValue("");
      } else {
        alert('There was a problem sending your message. Please try again!')
      }
    });

  
  };

  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    if (value) {
      console.log("Captcha valid");
      setCaptchaValid(true);
    }
  };

  const updateEmailValue = (event) => {
    // console.log()
    setEmailValue(event.target.value);
  };

  const updateMessageValue = (event) => {
    setMessageValue(event.target.value);
  };

  const [captchaValid, setCaptchaValid] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  return (
    <Element name="contactus">
      <div className="bg-blue-700 py-8 sm:py-12 lg:py-16">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
            <h2 className="inline sm:block lg:inline xl:block">
              Want to know more?
            </h2>{" "}
            <p className="inline sm:block lg:inline xl:block">
              Send us a message!
            </p>
          </div>
          <form className="w-full max-w-md lg:col-span-5">
            <div className="">
              <label
                htmlFor="email-address"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
                value={emailValue}
                onChange={updateEmailValue}
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Message
              </label>
              <div className="">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Let us know what you would like to discuss."
                  value={messageValue}
                  onChange={updateMessageValue}
                />
              </div>
            </div>
            <ReCAPTCHA
              className="pt-2.5"
              sitekey="6Ldx6i0pAAAAAGDg_XLVbW7N4OpWfINxhfcwpbqz"
              onChange={onCaptchaChange}
            />

            <button
              type="button"
              disabled={!captchaValid || !messageValue || !emailValue}
              className={classNames(
                "flex-none rounded-md bg-white px-3.5 my-2 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white",
                captchaValid && messageValue && emailValue
                  ? "text-blue-600 hover:bg-blue-50 "
                  : "text-gray-600"
              )}
              onClick={submitMessageHandler}
            >
              Subscribe
            </button>
          </form>
        </div>

        <footer className="">
          <div className="mx-auto max-w-7xl px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:order-1 md:mt-0">
              <p className="text-center text-xs leading-5 text-white">
                &copy; {new Date().getFullYear()}{" "}
                <a href="https://vandy-mhealth.github.io">
                  MHGH Lab @ Vanderbilt University
                </a>
                . All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </Element>
  );
}
